import { useParams } from 'react-router-dom'
import { useAdminGetAssetQuery, useAdminUpdateAssetMutation } from 'api'
import AdminAssetForm, { AdminAssetFormInputs } from 'components/admin/forms/asset'
import Spinner from 'components/spinner'
import FormHeader from 'components/admin/form_header'
import { HorizontalDivider } from 'components/Dividers/horizontal'
import FormLayout from 'components/admin/forms/layouts/FormLayout'
import Button from 'components/button'
import { AccessRequestLayout } from 'layouts/AccessRequestLayout'
import { useToast } from 'hooks/useToast'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

const AdminAssetManagePage = () => {
  const { id: assetId } = useParams<{ id: string }>()
  const { successToast, errorToast } = useToast()

  const { data: asset } = useAdminGetAssetQuery(parseInt(String(assetId)), { skip: !assetId })

  const [updateAsset, {
    error: updateError,
    isLoading: isUpdatingAsset,
  }] = useAdminUpdateAssetMutation()

  const handleSubmit = async (inputs: AdminAssetFormInputs) => {
    await updateAsset({
      ...inputs,
      id: parseInt(String(assetId)),
      theme_id: inputs.theme_id ? inputs?.theme_id : null, // Default theme_id to null if no theme selected
    })
      .unwrap()
      .then(() => successToast({
        title: 'Asset Updated',
        message: 'The asset has been updated',
      }))
      .catch((err) => {
        if (err?.status === 422) return

        errorToast({
          title: 'Asset Update Failed',
          message: 'Unable to update this asset at the moment, please try again later',
        })
      })
  }

  if (!asset) {
    return (
      <div className="flex justify-center p-3">
        <Spinner className="text-primary-gray" />
      </div>
    )
  }

  return (
    <AccessRequestLayout
      userId={asset?.user_id}
      blockRedirect={!asset?.user_id}
      redirectUrl="/admin/dashboard/assets/browse"
    >
      <div className="flex flex-col h-full">
        <FormHeader
          title="Manage Asset"
          description="Manage Asset information"
          backBtnText="Back to Assets"
          backBtnUrl="/admin/dashboard/assets/browse"
        />

        <AdminAssetForm
          asset={asset}
          onSubmit={handleSubmit}
          btnTxt="Update Asset"
          defaultValues={{
            client_id: asset?.user?.client_id ?? asset?.theme?.client_id ?? null,
            theme_id: asset?.theme?.id ?? null,
            description: asset?.description ?? '',
            default_message_content: asset?.messages?.find(message => message?.is_default)?.content ?? '',
            default_message_name: asset?.messages?.find(message => message?.is_default)?.name ?? '',
            is_private: asset?.is_private ?? false,
            is_activated: asset?.is_activated ?? false,
            line_1: asset?.line_1 ?? '',
            line_2: asset?.line_2 ?? '',
            town_or_city: asset?.town_or_city ?? '',
            county: asset?.county ?? '',
            country: asset?.country ?? '',
            postcode: asset?.postcode ?? '',
            note: asset?.note ?? '',
          }}
          errors={(updateError as FetchBaseQueryError)?.status === 422 ? updateError : undefined}
          isLoading={isUpdatingAsset}
        />

        <HorizontalDivider />

        <FormLayout>
          <FormLayout.Block>
            <div className="flex items-center justify-between gap-5">
              <FormLayout.Title
                title="Credit Usage"
                subtitle="View the credit usage for this asset"
              />
              <Button
                href={`/admin/dashboard/credit-logs?search=${asset?.masked_uid}`}
              >
                View Credit Usage
              </Button>
            </div>
          </FormLayout.Block>
        </FormLayout>
      </div>
    </AccessRequestLayout>
  )
}

export default AdminAssetManagePage